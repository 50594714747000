const { isNil } = require("lodash");

const getWindMetrics = frames => {
  const { vx, vy } = calculateVectors(frames);

  return {
    averageWindSpeed: calculateAverageSpeed(vx, vy),
    averageWindDirection: calculateAverageDirection(vx, vy)
  };
};

const calculateVectors = frames => {
  let vx = 0;
  let vy = 0;

  for (const image of frames) {
    const telemetry = image?.telemetry;
    const { wind_speed: speed, wind_direction: direction } = telemetry || {};

    const angle = degreesToRadians(direction);
    vx += speed * Math.cos(angle);
    vy += speed * Math.sin(angle);
  }

  return normalizeVectors(vx, vy, frames.length);
};

const degreesToRadians = degrees => degrees * (Math.PI / 180);

const roundToDecimals = (number, decimals = 2) => {
  const factor = 10 ** decimals;
  return Math.round(number * factor) / factor;
};

const normalizeVectors = (vx, vy, vectorCount) => {
  const x = vx / vectorCount;
  const y = vy / vectorCount;
  vx = roundToDecimals(x);
  vy = roundToDecimals(y);

  return { vx, vy };
};

const calculateAverageSpeed = (vx, vy) => {
  const speed = Math.sqrt(vx ** 2 + vy ** 2);

  return roundToDecimals(speed);
};

const calculateAverageDirection = (vx, vy) => {
  const direction = Math.atan2(vy, vx) * (180 / Math.PI);
  const roundedDirection = Math.round(direction * 100) / 100;

  return roundedDirection < 0 ? roundedDirection + 360 : roundedDirection;
};

const filterValidFrames = frames => {
  if (isNil(frames) || frames?.length === 0) return [];
  return frames.filter(frame => {
    const speed = frame?.telemetry?.wind_speed;
    const direction = frame?.telemetry?.wind_direction;

    return !(isNil(speed) || isNil(direction) || speed < 0 || direction < 0);
  });
};

module.exports = {
  getWindMetrics,
  calculateVectors,
  calculateAverageSpeed,
  calculateAverageDirection,
  filterValidFrames
};
