const {
  getWindMetrics,
  calculateVectors,
  calculateAverageSpeed,
  calculateAverageDirection,
  filterValidFrames
} = require("./calculations");

module.exports = {
  getWindMetrics,
  calculateVectors,
  calculateAverageSpeed,
  calculateAverageDirection,
  filterValidFrames
};
