import { addMinutes, subMinutes } from "date-fns";
import { cloneDeep, isNil } from "lodash";

export const writeToCache = (key, value) =>
  localStorage.setItem(key, JSON.stringify(value));
export const readFromCache = key =>
  JSON.parse(localStorage.getItem(key)) || null;

//TODO: This is a temp solution to remove duplicate images and should happen in the backend
//TODO: should be handled in the backend!!!!
export const filterDuplicates = response => {
  let newArray = [];
  response.forEach(image => {
    let duplicate = false;
    newArray.forEach(newImage => {
      if (image.createdOn === newImage.createdOn) {
        duplicate = true;
      }
    });
    if (!duplicate) {
      newArray.push(image);
    }
  });
  return newArray;
};

export const groupBy = (list, prop) => {
  const indexList = {};
  let index = 0;
  return list.reduce((acc, curr) => {
    const identifier = curr[prop] || 0;
    const pos = indexList[identifier];
    if (pos === undefined) {
      indexList[identifier] = index;
      index++;
      acc.push([]);
    }
    const currPos = indexList[identifier];
    acc[currPos].push(curr);

    return acc;
  }, []);
};

export const getUnique = (alarms, newAlarms) => {
  newAlarms?.map(x =>
    alarms.find(a => a.id === x.id) ? null : alarms.push(x)
  );
  return alarms;
};

export const getDifference = (array1, array2) => {
  return array1.filter(object1 => {
    return !array2.some(object2 => {
      return object1.id === object2.id;
    });
  });
};

export const toXYValues = (values, frames) =>
  values.map((y, i) => {
    const frame = frames[i];
    const x = frame?.createdOn ?? i;
    return { x, y };
  });

export const changeValueOfLastFirstX = data => {
  const firstX = data.at(0).x;
  const lastX = data.at(-1).x;
  const first = subMinutes(new Date(firstX), 1).toISOString();
  const last = addMinutes(new Date(lastX), 1).toISOString();

  const updatedData = cloneDeep(data);
  updatedData[0].x = first;
  updatedData[data.length - 1].x = last;
  return updatedData;
};

export const setAnchor = (
  anchorV,
  anchorH,
  transformV = "top",
  transformH = "left"
) => {
  const anchorOrigin = {};
  const transformOrigin = {
    vertical: transformV,
    horizontal: transformH
  };
  if (anchorV) anchorOrigin.vertical = anchorV;
  if (anchorH) anchorOrigin.horizontal = anchorH;
  return { anchorOrigin, transformOrigin };
};

export const formatDuration = duration => {
  if (isNil(duration) || isNaN(duration)) return "N/A";
  if (duration === 1) return `${duration} minute`;

  return `${duration} minutes`;
};
